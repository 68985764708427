import { Component, Input, OnInit, AfterViewInit, ElementRef, OnDestroy, OnChanges } from '@angular/core';
import { ThemeService } from 'src/app/service/theme.service';
import {fromEvent} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Component({
	selector: 'grid-wrapper',
	template: `
	<ng-content></ng-content>
    `,
	styles: [
		` 
		  :host {
			display: grid;
			position: relative;
			top: 0;
			left: -10px;
			width: calc(100% + 5px);
			gap: 10px;
			overflow-y: auto;
			justify-content: space-evenly;
			grid-template-columns: repeat(auto-fit, 100px);
		  }
		  
		  :host ::-webkit-scrollbar {
			width: 4px;
			background-color: transparent;
		  }
		
		  :host ::-webkit-scrollbar-thumb {
				background-color: rgba(0, 0, 0, 0.4);
				background-color: var(--scrollbar-color, rgba(0, 0, 0, 0.4) );
				width: 6px;
				border-radius: 6px;
		  }
		
		`
	]
})

export class GridWrapper implements OnInit, AfterViewInit, OnDestroy, OnChanges {
	@Input('') options:any={};
	private resize;
	private columnGap = 0;
	constructor(public theme: ThemeService, private elRef: ElementRef) {
	}

	ngOnInit(): void {
		this.initStyle();
	}

	ngAfterViewInit(): void {
		this.resize = fromEvent(window, 'resize').pipe(debounceTime(500)).subscribe(event=>{
			let wrapperWidth = this.elRef.nativeElement.offsetWidth;
			let remainder = wrapperWidth % this.options.gridWidth;
			let showedUnit = Math.floor(wrapperWidth / this.options.gridWidth);
			let margin = remainder / (showedUnit + 1);

			//未諗倒
		});
	}

	ngOnChanges(){
	}

	initStyle(){
		let style = this.elRef.nativeElement.style;
		if (this.options.gap){
			style.left = - this.options.gap + 'px';
			style.paddingLeft = (this.options.gap + 10) + 'px';
			style.columnGap = this.options.gap + 'px';
			style.rowGap = this.options.gap + 'px';
		}
		if (this.options.gridWidth){
			style.gridTemplateColumns = 'repeat(auto-fit,' + this.options.gridWidth +'px)'; 
		}
	}

	ngOnDestroy(): void {
		this.resize.unsubscribe();
	}





}
