import { Component, ElementRef, HostBinding } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, ICellRendererParams } from 'ag-grid-community';
import { ThemeService } from 'src/app/service/theme.service';

@Component({
	selector: "pieChartRenderer",
	template: `
	<pie-chart
		*ngIf="params && params.pieChart"
		[data]="params.pieChart.data"
		[size]="params.pieChart.size"
		[options]="(params.pieChart.options?params.pieChart.options:{blankColor: '#ccc'})"
		[style.width.px]="params.pieChart.size"
		[style.height.px]="params.pieChart.size"
		[ngStyle]="params.pieChart.style"
		(click)="pieChartClick($event)"
	></pie-chart>
	<div class="total" *ngIf="params.show"><div class="text">{{num}}/{{total}}</div></div>

	`,
	styleUrls: ['./pieChart.renderer.scss']
})


export class PieChartRenderer {
	@HostBinding('style.backgroundColor') public backgroundColor = '';
	@HostBinding('style.color') public color = '';

	public params: any;
	public text:string = '';
	public total = 0;
	public num = 0 ;

	constructor(private themeService:ThemeService, eleRef:ElementRef) {
		themeService.applyStyleObj({
			"default": {
				"pieChartRendererBgColor": "#F1F1F1",
				"textColor":"#333333",
				"disableBgColor":"#ffffff"
			},
			"dark": {
				"pieChartRendererBgColor": "#1A1A23",
				"textColor":"#a0a0a0",
				"disableBgColor":"#1E1E29"
			}
		}, eleRef.nativeElement);
	}

	agInit(params: ICellRendererParams): void {
		this.params = params;
		if (this.params.show){
            this.changeBgColor(params)
		    this.countTotal(params)
		}

		/*if (this.params.backgroundColor) this.backgroundColor = this.params.backgroundColor;
		if (this.params.color) this.color = this.params.color;
		if (this.params.text) this.text = this.params.text;*/
	}

	pieChartClick($event){
		if (this.params.click && this.params.pieChart.data.length > 0){
			this.params.click();
		}
	}

	countTotal(params){
		let pieChartData = params.pieChart.data
		let firstRow = pieChartData[0]
		if (params.colDef.field == 'join'  || params.colDef.field == '_display_name' ){
			pieChartData.map(row => {
				this.total = this.total + row.value
				this.num = firstRow.value
			})
		} else {
			pieChartData.map(row => {
				this.total = this.total + row.value
				this.num = this.total - firstRow.value
				
			})
		}
	}

	changeBgColor (params){
		let field = params.colDef.field
		let data = params.pieChart.data
	
		if (field == 'join'){
			data[1].color = this.themeService.themeSelected=='light' ? '#fff' : '#2d2c44'
		}
		if (field == 'attend'){
			data[0].color = this.themeService.themeSelected=='light' ? '#CCCCCC' : '#2d2c44'
		}
		if (field == 'payment' || field == 'role' || field == 'assemble' || field == 'leave' || field == 'meal' || field == 'late' || field == 'early_leave'){
			data[0].color = this.themeService.themeSelected=='light' ? '#fff' : '#2d2c44'
		}
		
	}
}
