import { Injectable } from '@angular/core';
import { commonService } from './common.service';
@Injectable()

export class ModalService {
  public modalList:any[] = [];

  constructor(public commonService:commonService){}

  create(obj:Object){
    console.log('modal')
    this.modalList.push(obj);
  }

}
