import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PieChartModule } from '../../pieChartModule/pieChart.module';
import { PieChartRenderer } from './pieChart.renderer';

@NgModule({
    imports: [
		CommonModule,
		PieChartModule
    ],
    declarations: [
		PieChartRenderer
    ],
    exports: [
		PieChartRenderer
    ],
    providers: [
		
    ],
    entryComponents: [ 
		PieChartRenderer
	]
})
export class PieChartRendererModule {
}
