import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class LocalMediaService {
    
    public videoStream!:MediaStream;
    public displayStream!:MediaStream|any;

    private videoConstraints:any = {
        test_1: { video: {width: {exact: 160}, height: {exact: 120}}, audio:true },
        qvgaConstraints: { video: {width: {exact: 320}, height: {exact: 240}}, audio:true },
        vgaConstraints: { video: {width: {exact: 640}, height: {exact: 480}}, audio:true },
        hdConstraints: { video: {width: {exact: 1280}, height: {exact: 720}}, audio:true },
        fullHdConstraints: { video: {width: {exact: 1920}, height: {exact: 1080}}, audio:true },
        televisionFourKConstraints: { video: {width: {exact: 3840}, height: {exact: 2160}}, audio:true },
        cinemaFourKConstraints: { video: {width: {exact: 4096}, height: {exact: 2160}}, audio:true },
        eightKConstraints: { video: {width: {exact: 7680}, height: {exact: 4320}}, audio:true }
    }

    private displayConstraints:any = {
        test_1: { video: {width: {exact: 160}, height: {exact: 120}}, audio:true },
        qvgaConstraints: { video: {width: 320, height: 240}, audio:true },
        vgaConstraints: { video: {width: 640, height: 480}, audio:true },
        hdConstraints: { video: {width: 1280, height: 720}, audio:true },
        fullHdConstraints: { video: {width: {exact: 1920}, height: {exact: 1080}}, audio:true },
        televisionFourKConstraints: { video: {width: {exact: 3840}, height: {exact: 2160}}, audio:true },
        cinemaFourKConstraints: { video: {width: {exact: 4096}, height: {exact: 2160}}, audio:true },
        eightKConstraints: { video: {width: {exact: 7680}, height: {exact: 4320}}, audio:true }
    }
    
    constructor(public coms:CommonService) {
        /*if (!environment.production) {
            [this.videoConstraints, this.displayConstraints].map((constraints:any)=>{
                for (let key in constraints) {
                    constraints[key].audio = false;
                }
            });
        }*/
    }
    //---------------------------------------------------------------------------------------------
    public getUserMedia():Promise<void | MediaStream> {
        if (this.videoStream) return Promise.resolve(this.videoStream);

        return navigator.mediaDevices.getUserMedia(this.videoConstraints.qvgaConstraints).then((stream:MediaStream) => {
            if (stream) this.videoStream = stream;
            return this.videoStream;
        }).catch((err)=>{
            this.coms.log(err);
        });;
    }
    //---------------------------------------------------------------------------------------------
    public getDisplayMedia():Promise<void | MediaStream> {
        if (this.displayStream) return Promise.resolve(this.displayStream);
        // @ts-ignore
        return navigator.mediaDevices.getDisplayMedia(this.displayConstraints.hdConstraints).then((stream:MediaStream) => {
            this.displayStream = stream;
            return this.displayStream;
        });
    }
    //---------------------------------------------------------------------------------------------
    public closeDisplayMedia():void {
        if (this.displayStream) {
            let tracks:MediaStreamTrack[] = this.displayStream.getTracks();
            for (let track of tracks) track.stop();
            this.displayStream = null;
        }
    }//---------------------------------------------------------------------------------------------
    public getFirstTrack(stream:MediaStream=this.videoStream, type:string="video"):MediaStreamTrack {
        let result:any = null;
        if (stream) {
            if (type=='video') {
                result = stream.getVideoTracks()[0];
            } else if (type=='audio') {
                result = stream.getAudioTracks()[0];
            }
        }
        return result;
    }
    //---------------------------------------------------------------------------------------------
    public isTrackEnabled(stream:MediaStream=this.videoStream, type:string="video"):boolean {
        let track:MediaStreamTrack = this.getFirstTrack(stream, type);
        return track && track.enabled;
    }
    //---------------------------------------------------------------------------------------------
    public setTrackEnable(stream:MediaStream=this.videoStream, type:string="video", value=true):void {
        let track:MediaStreamTrack = this.getFirstTrack(stream, type);
        if (track) track.enabled = value;
    }
    //---------------------------------------------------------------------------------------------
    public toggleTrackEnable(stream:MediaStream=this.videoStream, type:string="video"):void {
        let curValue:boolean = this.isTrackEnabled(stream, type);
        this.setTrackEnable(stream, type, !curValue);
    }
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------

}
