import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridWrapper } from './grid.wrapper';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
		GridWrapper
    ],
    exports: [
		GridWrapper
    ],
    providers: [
    ],
    entryComponents: []
})
export class GridWrapperModule {
}
