import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NoRowsOverlay } from './noRows.overlay';

@NgModule({
    imports: [
		CommonModule,
		TranslateModule
    ],
    declarations: [
		NoRowsOverlay
    ],
    exports: [
		NoRowsOverlay
    ],
    providers: [
    ],
    entryComponents: [ 
		NoRowsOverlay
	]
})
export class NoRowsOverlayModule {
}
