import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({ name: 'date_format' })
export class DateFormatPipe implements PipeTransform {
	transform(value: any): string {
		if (value) {
			return moment(value).format("yyyy/MM/DD");
		} else {
			return "";
		}
	}
}